// export const BaseAPIUrl = process.env.REACT_APP_SECURITY_URL
//   ? process.env.REACT_APP_SECURITY_URL
//   : "http://localhost:5000";

export const BaseAPIUrl = process.env.REACT_APP_SECURITY_URL ? process.env.REACT_APP_SECURITY_URL : "https://trade.sinisana.net/security";
// export const BaseAPIUrl = process.env.REACT_APP_SECURITY_URL;

export const httpFetch = (url, config) => {
  return fetch(BaseAPIUrl + url, config)
    .then((res) => res.json())
    .catch((err) => err);
};
