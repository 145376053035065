export const BaseAPIUrl = process.env.REACT_APP_SERVER_URL
  ? process.env.REACT_APP_SERVER_URL + "/_1125"
  : "http://localhost:4000/_1125";

export const httpFetch = (url, config) => {
  return fetch(BaseAPIUrl + url, config)
    .then((res) => {
      const contentType = res.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        return res.json();
      } else {
        return res;
      }
    })
    .catch((err) => err);
};
