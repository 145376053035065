import { lazy } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

// project imports
import AdminLayout from "../components/layouts/AdminLayout";
import Loadable from "../components/loadable/Loadable";

const Profile = Loadable(lazy(() => import("../views/profile/profile")));

// Dashboard
const Sector = Loadable(lazy(() => import("../modules/dashboard/Sector")));
const SectorDetail = Loadable(lazy(() => import("../modules/dashboard/SectorDetail")));
const Establishment = Loadable(lazy(() => import("../modules/dashboard/Establishment")));
const EstablishmentDetail = Loadable(lazy(() => import("../modules/dashboard/EstablishmentDetail")));
const DashboardPage = Loadable(lazy(() => import("../views/dashboard/DashboardPage")));
const DashboardMap = Loadable(lazy(() => import("../views/dashboard/DashboardMap")));

// Disputes routing
const Production = Loadable(lazy(() => import("../views/production/Production")));
const NewProduction = Loadable(lazy(() => import("../views/production/NewProduction")));
const ProductionDetail = Loadable(lazy(() => import("../views/production/ProductionDetail")));
const Pallet = Loadable(lazy(() => import("../views/pallet/Pallet")));

// account routing
const AccountSetting = Loadable(lazy(() => import("../views/account/AccountSetting")));

// Settings
// const Option = Loadable(lazy(() => import("../views/settings/option/Option")));
// const LitsaraProductOptionDetail = Loadable(lazy(() => import("../views/settings/option/LitsaraProductOptionDetail")));

// Sinisana
// const Account = Loadable(lazy(() => import("../views/settings/account/Account")));
// const AccountDetail = Loadable(lazy(() => import("../views/settings/account/AccountDetail")));
const Entity = Loadable(lazy(() => import("../views/settings/entity/Entity")));
const Organization = Loadable(lazy(() => import("../views/sinisana/organization/Organization")));
const OrganizationDetail = Loadable(lazy(() => import("../views/sinisana/organization/OrganizationDetail")));
const Security = Loadable(lazy(() => import("../views/sinisana/security/Security")));
const SecurityDetail = Loadable(lazy(() => import("../views/sinisana/security/SecurityDetail")));

const PermissionDenied = () => {
  return (
    <Grid container padding={{ xs: 0, md: 2 }} marginTop={{ md: "24px" }} spacing={2}>
      <Grid item xs={12}>
        <Box>
          <h1>Permission Denied</h1>
          <p>You don't have permission to access this page. Please contact your administrator.</p>
        </Box>
      </Grid>
    </Grid>
  );
};

// ==============================|| MAIN ROUTING ||============================== //
const AdminRoutes = (can) => {
  return {
    path: "/admin",
    element: <AdminLayout />,
    children: [
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "dashboard/establishments",
        element: <Establishment />,
      },
      {
        path: "dashboard/establishments/:id",
        element: <EstablishmentDetail />,
      },
      {
        path: "dashboard/sectors",
        element: <Sector />,
      },
      {
        path: "dashboard/sectors/detail/:name",
        element: <SectorDetail />,
      },
      {
        path: "dashboard/dashboardPage",
        element: can("DashboardPage") ? <DashboardPage /> : <PermissionDenied />,
      },
      {
        path: "dashboard/map",
        element: can("DashboardPage") ? <DashboardMap /> : <PermissionDenied />,
      },
      {
        path: "/admin/manage/productions",
        element: can("Dashboard") ? <Production /> : <PermissionDenied />,
      },
      {
        path: "/admin/manage/productions/new",
        element: can("Dashboard") ? <NewProduction /> : <PermissionDenied />,
      },
      {
        path: "/admin/manage/productions/:id",
        element: can("Dashboard") ? <ProductionDetail /> : <PermissionDenied />,
      },
      {
        path: "/admin/manage/pallets",
        element: can("Dashboard") ? <Pallet /> : <PermissionDenied />,
      },

      {
        path: "/admin/account/settings",
        element: can("AccountSetting") ? <AccountSetting /> : <PermissionDenied />,
      },

      {
        path: "sinisana/security",
        element: can("Sinisana") ? <Security /> : <PermissionDenied />,
      },
      {
        path: "sinisana/security/:id",
        element: can("Sinisana") ? <SecurityDetail /> : <PermissionDenied />,
      },

      {
        path: "sinisana/entity",
        element: can("Sinisana") ? <Entity /> : <PermissionDenied />,
      },
      {
        path: "sinisana/organizations",
        element: can("Sinisana") ? <Organization /> : <PermissionDenied />,
      },
      {
        path: "sinisana/organizations/:id",
        element: can("Sinisana") ? <OrganizationDetail /> : <PermissionDenied />,
      },
    ],
  };
};

export default AdminRoutes;
