import { useCallback, useContext } from "react";
import { httpFetch } from "../../../services/AdminApiService";
import { httpFetch as webHttpFetch } from "../../../services/PublicRouteService";
import { AuthContext, fp } from "../../../App";

const useAccountService = () => {
  const { authState } = useContext(AuthContext);

  const findAll = useCallback(async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: "Bearer " + authState.token,
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
    };

    return await httpFetch("/accounts", config);
  }, [authState.token]);

  const create = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await httpFetch("/accounts", config);
    },
    [authState.token]
  );

  const findOne = useCallback(
    async (id) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await httpFetch("/accounts/" + id, config);
    },
    [authState.token]
  );

  const update = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await httpFetch("/accounts/" + id, config);
    },
    [authState.token]
  );

  const destroy = useCallback(
    async (id) => {
      const config = {
        method: "delete",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await httpFetch("/accounts/" + id, config);
    },
    [authState.token]
  );

  const findEntity = useCallback(async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: "Bearer " + authState.token,
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
    };

    return await httpFetch("/accounts/findEntity", config);
  }, [authState.token]);

  const addEntity = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await httpFetch("/accounts/" + id + "/addEntity", config);
    },
    [authState.token]
  );

  const removeEntity = useCallback(
    async (id, params) => {
      const config = {
        method: "delete",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await httpFetch("/accounts/" + id + "/removeEntity", config);
    },
    [authState.token]
  );

  const requestAccountDeletion = useCallback(async (params) => {
    const config = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
      body: JSON.stringify(params),
    };

    return await webHttpFetch("/accounts/requestAccountDeletion", config);
  }, []);

  const destroyAccount = useCallback(async (params) => {
    const config = {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
      body: JSON.stringify(params),
    };

    return await webHttpFetch("/accounts/destroyAccount", config);
  }, []);

  return {
    findAll,
    create,
    findOne,
    update,
    destroy,
    findEntity,
    addEntity,
    removeEntity,
    requestAccountDeletion,
    destroyAccount,
  };
};
export { useAccountService };
