// material-ui
import { Typography } from "@mui/material";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import InventoryIcon from "@mui/icons-material/Inventory";
import Grid4x4Icon from "@mui/icons-material/Grid4x4";
import MapIcon from "@mui/icons-material/Map";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import AgricultureIcon from "@mui/icons-material/Agriculture";

// project imports
import NavGroup from "./NavGroup";
import { EntityContext } from "../../../App";
import { useContext } from "react";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const menuItems = {
  items: [
    // {
    //   id: "dashboard",
    //   title: "Dashboard",
    //   type: "group",
    //   children: [
    //     {
    //       id: "sector",
    //       title: "Sectors",
    //       type: "item",
    //       url: "/admin/dashboard/sectors",
    //       icon: AnalyticsIcon,
    //     },
    //     {
    //       id: "establishment",
    //       title: "Establishments",
    //       type: "item",
    //       url: "/admin/dashboard/establishments",
    //       icon: AgricultureIcon,
    //     },
    //   ],
    // },
    {
      id: "sinisana",
      title: "Sinisana",
      type: "group",
      permission: "Sinisana",
      children: [
        {
          id: "security",
          title: "Security",
          type: "item",
          url: "/admin/sinisana/security",
        },
        // {
        //   id: "account",
        //   title: "Account",
        //   type: "item",
        //   url: "/admin/sinisana/account",
        // },
        {
          id: "organization",
          title: "Organization",
          type: "item",
          url: "/admin/sinisana/organizations",
        },
      ],
    },

    {
      id: "dashboard",
      title: "Dashboard",
      type: "group",
      permission: "Dashboard",
      children: [
        {
          id: "dashboardPage",
          title: "Dashboard",
          type: "item",
          url: "/admin/dashboard/dashboardPage",
          icon: DesktopWindowsOutlinedIcon,
        },
        {
          id: "map",
          title: "Map",
          type: "item",
          url: "/admin/dashboard/map",
          icon: MapIcon,
        },
      ],
    },
    {
      id: "manage",
      title: "Manage",
      type: "group",
      permission: "Dashboard",
      children: [
        {
          id: "productions",
          title: "Productions",
          type: "item",
          url: "/admin/manage/productions",
          icon: InventoryIcon,
        },
        {
          id: "pallets",
          title: "My Pallets",
          type: "item",
          url: "/admin/manage/pallets",
          icon: Grid4x4Icon,
        },
      ],
    },
    // {
    //   id: "transactions",
    //   title: "Transactions",
    //   type: "group",
    //   permission: "Transaction",
    //   children: [
    //     {
    //       id: "paymentBatch",
    //       title: "Production",
    //       type: "item",
    //       url: "/admin/transactions/paymentBatch",
    //       icon: AccountBalanceWalletOutlinedIcon,
    //     },
    //   ],
    // },
    // {
    //   id: "settings",
    //   title: "Settings",
    //   type: "group",
    //   permission: "Settings",
    //   children: [
    //     {
    //       id: "productionWorkers",
    //       title: "Workers",
    //       type: "item",
    //       url: "/admin/settings/productionWorkers",
    //       icon: GroupsOutlinedIcon,
    //     },
    //     {
    //       id: "surveys",
    //       title: "Surveys",
    //       type: "item",
    //       url: "/admin/settings/surveys",
    //       icon: PollIcon,
    //     },
    //   ],
    // },
  ],
};

const MenuList = () => {
  const { can } = useContext(EntityContext);

  const navItems = menuItems.items
    .filter((item) => {
      if (!item.permission) return true;
      return can(item.permission);
    })
    .map((item) => {
      switch (item.type) {
        case "group":
          return <NavGroup key={item.id} item={item} />;
        default:
          return (
            <Typography key={item.id} variant="h6" color="error" align="center">
              Menu Items Error
            </Typography>
          );
      }
    });

  return <>{navItems}</>;
};

export default MenuList;
