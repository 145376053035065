import { useCallback, useContext } from "react";
import { httpFetch } from "../../../services/AdminApiService";
import { AuthContext, fp } from "../../../App";

const useEntityService = () => {
  const { authState } = useContext(AuthContext);

  const findAll = useCallback(
    async (query = {}) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await httpFetch("/entities?type=" + query.type + "&searchText=" + query.searchText, config);
    },
    [authState.token]
  );

  const create = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await httpFetch("/entities", config);
    },
    [authState.token]
  );

  const findOne = useCallback(
    async (id) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await httpFetch("/entities/" + id, config);
    },
    [authState.token]
  );

  const update = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await httpFetch("/entities/" + id, config);
    },
    [authState.token]
  );

  const destroy = useCallback(
    async (id) => {
      const config = {
        method: "delete",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await httpFetch("/entities/" + id, config);
    },
    [authState.token]
  );

  const updateFlureeId = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await httpFetch("/entities/" + id + "/updateFlureeId", config);
    },
    [authState.token]
  );

  const findPermission = useCallback(
    async (id) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await httpFetch("/entities/" + id + "/findPermission", config);
    },
    [authState.token]
  );

  const addStory = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await httpFetch("/entities/" + id + "/addStory", config);
    },
    [authState.token]
  );

  const removeStory = useCallback(
    async (id, params) => {
      const config = {
        method: "delete",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await httpFetch("/entities/" + id + "/removeStory", config);
    },
    [authState.token]
  );

  const findStory = useCallback(
    async (id, storyId) => {
      const config = {
        method: "get",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
      };

      return await httpFetch("/entities/" + id + "/stories/" + storyId, config);
    },
    [authState.token]
  );

  const updateStory = useCallback(
    async (id, storyId, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await httpFetch("/entities/" + id + "/stories/" + storyId, config);
    },
    [authState.token]
  );

  const uploadStoryImage = useCallback(
    async (id, storyId, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          FpId: (await fp).visitorId,
        },
        body: params,
      };

      return await httpFetch("/entities/" + id + "/stories/" + storyId + "/uploadStoryImage", config);
    },
    [authState.token]
  );

  const removeStoryImage = useCallback(
    async (id, storyId, params) => {
      const config = {
        method: "delete",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await httpFetch("/entities/" + id + "/stories/" + storyId + "/removeStoryImage", config);
    },
    [authState.token]
  );

  const addEntityManagement = useCallback(
    async (id, params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await httpFetch("/entities/" + id + "/addEntityManagement", config);
    },
    [authState.token]
  );

  const findEntityByToken = useCallback(async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: "Bearer " + authState.token,
        "Content-Type": "application/json",
        FpId: (await fp).visitorId,
      },
    };

    return await httpFetch("/entities/findEntityByToken", config);
  }, [authState.token]);

  const findEntityPermission = useCallback(
    async (params) => {
      const config = {
        method: "post",
        headers: {
          Authorization: "Bearer " + authState.token,
          "Content-Type": "application/json",
          FpId: (await fp).visitorId,
        },
        body: JSON.stringify(params),
      };

      return await httpFetch("/entities/findEntityPermission", config);
    },
    [authState.token]
  );

  return {
    findAll,
    create,
    findOne,
    update,
    destroy,
    updateFlureeId,
    findPermission,
    addStory,
    removeStory,
    findStory,
    updateStory,
    uploadStoryImage,
    removeStoryImage,
    addEntityManagement,
    findEntityByToken,
    findEntityPermission,
  };
};
export { useEntityService };
